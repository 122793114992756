import React from 'react'
import Pagination from 'react-js-pagination'
import '../assets/css/pagination.css'
function Paginations({ items , pageNo , handlePageChange , Scroll}) {
    return (
        <>
            <Pagination 
                itemClass="page-item"
                linkClass="page-link"
                containerClassName={'pagination'}
                activePage={pageNo}
                hideFirstLastPages={true}
                itemsCountPerPage={15}
                totalItemsCount={items?.allStrapiPost?.edges?.length || items?.articles?.edges?.length }
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </>
    )
}

export default Paginations