import { Link } from "gatsby";
import React from "react";
import Moment from "react-moment";
import { Add3Dots } from './subdesc'
const CategoryCards = ({ thumbnail_title, titleHeading, date, slug, thumbnail, catagory }) => {
    return (
        <>
            <div className="content_main_div">
                {/* Thumbnail_heading_div */}
                <Link to={`/article/${slug}`}>
                <div className="Thumbnail_heading_div ">
                    <img src={thumbnail} alt="slug" />
                </div>
                </Link>

                {/* text_button_date_main_div */}
                <div className=" text_button_date_main_div">
                    {/* title_heading_div */}
                    <div className="title_heading_div">
                        <Link to={`/article/${slug}`}>
                        
                            <p>{thumbnail_title ? Add3Dots(thumbnail_title, 25) : ""}</p>
                        </Link>
                    </div>
                    {/* button_date_div */}
                    <div className="button_date_div ">
                        {/* button_div */}
                        <div className="button_div">
                            <Link to={`/category/${catagory}`} className="button_link">
                                <button>{titleHeading}</button>
                            </Link>
                            {/* date_time_div */}
                        </div>
                        <div className="date_time_div">
                            <Moment format="MMM DD YYYY">{date}</Moment>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default CategoryCards;